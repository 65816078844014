import { Quantity } from 'contrib/aidbox';

/**
 * Local pressure unit is always mmHg
 */
export function convertToLocalPressureUnit(pressureQuantity: Quantity) {
    const quantityValue = pressureQuantity.value ?? 0;

    switch (pressureQuantity.unit) {
        case 'hPa':
            return quantityValue * 0.75;
        default:
            return quantityValue;
    }
}
