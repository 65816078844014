import moment from 'moment';

import { Observation, ObservationComponent } from 'contrib/aidbox';
import { parseFHIRDateTime } from 'utils/date';
import { mapMeasurementsHistoryToPeriod } from 'utils/measurements';

export const OBSERVATION_METHOD_MOOD_HISTORY_QUESTIONNAIRE = {
    code: 'mood-history-questionnaire',
    system: 'https://api.juli.co/code-systems/observation-methods',
};

export function getObservationCode(observation: Observation | ObservationComponent) {
    return observation.code.coding?.[0]?.code;
}

export function getObservationCategory(observation: Observation) {
    return observation.category?.[0]?.coding?.[0]?.code;
}

export function getObservationEffectiveDate(observation: Observation) {
    const effectiveDate = observation.effective?.dateTime ?? observation.effective?.Period?.end;

    return effectiveDate ? parseFHIRDateTime(effectiveDate) : undefined;
}

export function mapObservationsToPeriod<T>(
    observations: Observation[],
    reducer: (previousValue: T, observation: Observation, date: moment.Moment) => T,
    options: { periodDays: number; defaultValue: T; endDate?: moment.Moment },
): Array<T> {
    return mapMeasurementsHistoryToPeriod(observations, reducer, getObservationEffectiveDate, options);
}

export function findObservationComponent(observation: Observation, code: string) {
    return observation.component?.find((component) =>
        component.code?.coding?.map((componentCoding) => componentCoding.code)?.includes(code),
    );
}

export function compareEffectiveDate(first: Observation, second: Observation) {
    const firstEffectiveDate = getObservationEffectiveDate(first);
    const secondEffectiveDate = getObservationEffectiveDate(second);

    if (firstEffectiveDate === undefined) {
        return 1;
    } else if (secondEffectiveDate === undefined) {
        return -1;
    } else {
        if (firstEffectiveDate.isBefore(secondEffectiveDate)) {
            return -1;
        } else if (secondEffectiveDate.isBefore(firstEffectiveDate)) {
            return 1;
        } else {
            return 0;
        }
    }
}
