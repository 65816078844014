import moment from 'moment';

export const FHIRDateFormat = 'YYYY-MM-DD';
export const FHIRTimeFormat = 'HH:mm:ss';
export const FHIRTimeWithoutSecondsFormat = 'HH:mm';
export const FHIRDateTimeFormat = 'YYYY-MM-DDTHH:mm:ss[Z]';

export const isFHIRTime = (time: string) =>
    moment(time, [FHIRTimeFormat, FHIRTimeWithoutSecondsFormat], true).isValid();
export function startOfTodayInUTC() {
    return moment().utc().format(FHIRDateFormat);
}

export const formatFHIRTime = (date: Date | moment.Moment) => moment(date).utc().format(FHIRTimeFormat);
export const formatFHIRDate = (date: Date | moment.Moment) => moment(date).format(FHIRDateFormat);
export const formatFHIRDateTime = (date: Date | moment.Moment) => moment(date).utc().format(FHIRDateTimeFormat);

export const parseFHIRTime = (date: string) => moment.utc(date, FHIRTimeFormat);
export const parseFHIRDate = (date: string) => moment(date, FHIRDateFormat);
export const parseFHIRDateTime = (date: string) => moment.utc(date, FHIRDateTimeFormat).local();
