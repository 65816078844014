import { RemoteData, isNotAsked, isLoading, isFailure, isSuccess } from 'aidbox-react/lib/libs/remoteData';
import { formatError } from 'aidbox-react/lib/utils/error';

import { ActivityIndicator } from 'components/ActivityIndicator';

interface RenderRemoteDataProps<S, E = any> {
    remoteData: RemoteData<S, E>;
    children: (data: S) => React.ReactElement | null;
    renderFailure?: (error: E) => React.ReactElement | null;
    renderLoading?: () => React.ReactElement | null;
    renderNotAsked?: () => React.ReactElement | null;
}

function renderFailureDefault<E>(error: E) {
    return <div>{formatError(error)}</div>;
}

function renderLoadingDefault() {
    return <ActivityIndicator />;
}

export function RenderRemoteData<S>(props: RenderRemoteDataProps<S>) {
    const { remoteData, children, renderFailure, renderLoading, renderNotAsked } = props;

    if (isNotAsked(remoteData)) {
        return renderNotAsked ? renderNotAsked() : null;
    } else if (isLoading(remoteData)) {
        return (renderLoading ?? renderLoadingDefault)();
    } else if (isFailure(remoteData)) {
        return (renderFailure ?? renderFailureDefault)(remoteData.error);
    } else if (isSuccess(remoteData)) {
        return children(remoteData.data);
    } else {
        const n: never = remoteData;
        throw new Error(n);
    }
}
