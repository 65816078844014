import moment from 'moment';

import { WeatherHistory } from 'contrib/aidbox';
import { mapMeasurementsHistoryToPeriod } from 'utils/measurements';
import { parseFHIRDateTime } from 'utils/date';
import { periodDays, PeriodDuration } from 'utils/chart';
import { convertToLocalPressureUnit } from 'utils/units';

export function getWeatherHistoryEffectiveDate(weatherHistoryRecord: WeatherHistory) {
    return weatherHistoryRecord.date ? parseFHIRDateTime(weatherHistoryRecord.date) : undefined;
}

export function mapWeatherHistoryToPeriod<T>(
    history: WeatherHistory[],
    reducer: (previousValue: T, weatherHistoryRecord: WeatherHistory, date: moment.Moment) => T,
    options: { periodDays: number; defaultValue: T; endDate?: moment.Moment },
): Array<T> {
    return mapMeasurementsHistoryToPeriod(history, reducer, getWeatherHistoryEffectiveDate, options);
}
export function extractWeatherHistoryTemperatureMeasurements(
    history: WeatherHistory[],
    period: PeriodDuration,
    endDate?: moment.Moment,
): Array<number | undefined> {
    return history.length
        ? mapWeatherHistoryToPeriod<number | undefined>(
              history,
              (_, weatherHistoryRecord: WeatherHistory) =>
                  Math.round(weatherHistoryRecord.currentWeather.temperature.value!),
              { periodDays: periodDays(period), defaultValue: undefined, endDate },
          )
        : [];
}

export function extractWeatherHistoryHumidityMeasurements(
    history: WeatherHistory[],
    period: PeriodDuration,
    endDate?: moment.Moment,
): Array<number | undefined> {
    return history.length
        ? mapWeatherHistoryToPeriod<number | undefined>(
              history,
              (_, weatherHistoryRecord: WeatherHistory) =>
                  Math.round(weatherHistoryRecord.currentWeather.humidity.value!),
              { periodDays: periodDays(period), defaultValue: undefined, endDate },
          )
        : [];
}

export function extractWeatherHistoryAirPressureMeasurements(
    history: WeatherHistory[],
    period: PeriodDuration,
    endDate?: moment.Moment,
): Array<number | undefined> {
    return history.length
        ? mapWeatherHistoryToPeriod<number | undefined>(
              history,
              (_, weatherHistoryRecord: WeatherHistory) =>
                  Math.round(convertToLocalPressureUnit(weatherHistoryRecord.currentWeather.atmosphericPressure)),
              { periodDays: periodDays(period), defaultValue: undefined, endDate },
          )
        : [];
}
