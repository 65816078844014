import classNames from 'classnames';

import { PeriodDuration } from 'utils/chart';

import { left, right } from './images';
import s from './PatientDetails.module.css';

export interface PeriodNavigationProps {
    period: PeriodDuration;
    page: number;
    onChangePeriod: (period: PeriodDuration, page: number) => void;
}

export function PeriodNavigation(props: PeriodNavigationProps) {
    const { period, page, onChangePeriod } = props;

    const isNextPageDisabled = page >= 10;
    const isPreviousPageDisabled = page <= 1;

    return (
        <div className={s.tabsContainer}>
            <div className={s.tabs}>
                {Array.of<{ label: string; tag: PeriodDuration }>(
                    { label: '2 weeks', tag: 'two-weeks' },
                    { label: 'Month', tag: 'month' },
                    { label: '3 Months', tag: 'three-months' },
                ).map(({ label, tag }) => (
                    <button
                        key={tag}
                        type='button'
                        onClick={() => onChangePeriod(tag, 1)}
                        className={classNames(s.tab, {
                            [s.activeTab]: period === tag,
                        })}
                    >
                        {label}
                    </button>
                ))}
            </div>
            <div className={s.periodNavigation}>
                <button
                    type='button'
                    onClick={() => onChangePeriod(period, Math.min(page + 1, 10))}
                    className={classNames(s.periodNavigationControl, {
                        [s.disabledPeriodNavigationControl]: isNextPageDisabled,
                    })}
                    disabled={isNextPageDisabled}
                >
                    <img src={left} className={s.periodNavigationIcon} alt='switch to next period page' />
                </button>
                <button
                    type='button'
                    onClick={() => onChangePeriod(period, Math.max(page - 1, 1))}
                    className={classNames(s.periodNavigationControl, {
                        [s.disabledPeriodNavigationControl]: isPreviousPageDisabled,
                    })}
                    disabled={isPreviousPageDisabled}
                >
                    <img src={right} className={s.periodNavigationIcon} alt='switch to previous period page' />
                </button>
            </div>
        </div>
    );
}
