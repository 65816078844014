import { FormEvent, useCallback, useState } from 'react';
import { mapSuccess } from 'aidbox-react/lib/services/service';
import { Token } from 'aidbox-react/lib/services/token';

import { login } from 'services/auth';

import s from './SignIn.module.css';

export interface SignInProps {
    onSignIn?: (token: Token) => void;
}

export function SignIn(props: SignInProps) {
    const { onSignIn: onSignInCallback } = props;

    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const onSignIn = useCallback(
        async (event: FormEvent) => {
            event.preventDefault();

            return email && password
                ? login({ email, password }).then((tokenResponse) => {
                      mapSuccess(tokenResponse, (token) => onSignInCallback?.(token));
                  })
                : Promise.reject('Email and password are required');
        },
        [email, password, onSignInCallback],
    );

    return (
        <div className={s.container}>
            <form onSubmit={onSignIn} className={s.form}>
                <label className={s.line}>
                    Email:
                    <input
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type='email'
                        className={s.input}
                        autoFocus
                    />
                </label>
                <label className={s.line}>
                    Password:
                    <input
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type='password'
                        className={s.input}
                    />
                </label>
                <input type='submit' value='Sign In' className={s.button} />
            </form>
        </div>
    );
}
