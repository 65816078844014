import { useCallback } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { isSuccess } from 'aidbox-react/lib/libs/remoteData';

import { RenderRemoteData } from 'components/RenderRemoteData';
import { PeriodDuration } from 'utils/chart';

import { Overview } from './Overview';
import { HistoryCharts } from './HistoryCharts';
import { usePatientDetails } from './hooks';
import { AdditionalInformationTag } from './utils';
import s from './PatientDetails.module.css';

interface CustomSearchParams {
    details?: string;
    period?: PeriodDuration;
    page?: string;
    pinned?: string;
    folded?: string;
}

export function PatientDetails(_props: {}) {
    const routeParams = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    const currentPeriod = (searchParams.get('period') ?? 'two-weeks') as PeriodDuration;
    const currentPeriodPage = parseInt(searchParams.get('page') ?? '1');

    const { period, patientInformationResponse, patientHistoryResponse } = usePatientDetails(routeParams.patientId!, {
        duration: currentPeriod,
        page: currentPeriodPage,
    });

    const updateSearchParams = useCallback(
        (params: CustomSearchParams) =>
            setSearchParams(
                {
                    ...Object.fromEntries(searchParams.entries()),
                    ...params,
                },
                { replace: true },
            ),
        [searchParams, setSearchParams],
    );

    return (
        <div className={s.container}>
            <div className={s.overview}>
                {isSuccess(patientInformationResponse) ? <Overview patient={patientInformationResponse.data} /> : null}
            </div>
            <div className={s.details}>
                <RenderRemoteData remoteData={patientHistoryResponse}>
                    {(patientDetails) => (
                        <HistoryCharts
                            {...patientDetails}
                            key={`history-charts-${currentPeriod}-${currentPeriodPage}`}
                            period={period}
                            activeTab={searchParams.get('details') as AdditionalInformationTag | undefined}
                            activePage={currentPeriodPage}
                            pinned={(searchParams.get('pinned') ?? 'false') === 'true'}
                            folded={(searchParams.get('folded') ?? 'true') === 'true'}
                            onChangeDetailsTab={(details) => updateSearchParams({ details })}
                            onChangePeriod={(period, page) => updateSearchParams({ period, page: page.toString() })}
                            onPinMainChart={(pinned) => updateSearchParams({ pinned: pinned.toString() })}
                            onToggleDetails={(folded) => updateSearchParams({ folded: folded.toString() })}
                        />
                    )}
                </RenderRemoteData>
            </div>
        </div>
    );
}
