import { Observation, Period } from 'contrib/aidbox';
import { periodDays, PeriodDuration } from 'utils/chart';
import { parseFHIRDateTime } from 'utils/date';
import { mapObservationsToPeriod } from 'utils/observation';

type MomentPeriod = { start: moment.Moment; end: moment.Moment };

function convertToMomentPeriod(period: Period) {
    return {
        start: parseFHIRDateTime(period.start!),
        end: parseFHIRDateTime(period.end!),
    };
}

function areOverlappingPeriods(first: MomentPeriod, second: MomentPeriod) {
    return (
        (first.start.isSameOrBefore(second.start) && first.end.isSameOrAfter(second.start)) ||
        (first.start.isSameOrAfter(second.start) && first.start.isSameOrBefore(second.end))
    );
}

function joinOverlappingPeriods(first: MomentPeriod, second: MomentPeriod): MomentPeriod {
    return {
        start: first.start.isBefore(second.start) ? first.start : second.start,
        end: first.end.isAfter(second.end) ? first.end : second.end,
    };
}

export function extractSleepMeasurements(
    observations: Observation[],
    period: PeriodDuration,
    endDate?: moment.Moment,
): Array<number | undefined> {
    const observationsGroupedByDate = observations.length
        ? mapObservationsToPeriod(
              observations,
              (acc: Observation[], observation: Observation) => acc.concat(observation),
              { periodDays: periodDays(period), defaultValue: [], endDate },
          )
        : [];

    return observationsGroupedByDate.map((sameDateObservations: Observation[]) =>
        sameDateObservations
            .filter((observation) => {
                const period = observation.value?.Period;

                return Boolean(period?.start && period.end);
            })
            .map((observation) => convertToMomentPeriod(observation.value!.Period!))
            .reduce<MomentPeriod[]>((acc, period) => {
                const overlappingPeriodIndex = acc.findIndex((other) => areOverlappingPeriods(period, other));
                const overlappingPeriod = overlappingPeriodIndex === -1 ? undefined : acc[overlappingPeriodIndex];

                return overlappingPeriod
                    ? [
                          ...acc.slice(0, overlappingPeriodIndex),
                          ...acc.slice(overlappingPeriodIndex + 1),
                          joinOverlappingPeriods(overlappingPeriod, period),
                      ]
                    : [...acc, period];
            }, [])
            .reduce<number | undefined>(
                (acc, period) => (acc ?? 0) + period.end.diff(period.start, 'minutes'),
                undefined,
            ),
    );
}
