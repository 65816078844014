import React from 'react';
import ReactDOM from 'react-dom/client';

import { setInstanceUrl } from 'services/initialize';
import { App } from 'containers/App';

import './index.css';

setInstanceUrl();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
);
