import { Navigate, Route, Routes } from 'react-router-dom';

import { Header } from './Header';
import { PatientDetails } from './PatientDetails';
import { PatientList } from './PatientList';

export interface EpicProps {
    onLogout?: () => void;
}

export function Epic(props: EpicProps) {
    return (
        <Routes>
            <Route path='/' element={<Header {...props} />}>
                <Route index element={<Navigate to={{ pathname: 'patients' }} replace />} />
                <Route path='patients' element={<PatientList />} />
                <Route path='patient/:patientId' element={<PatientDetails />} />
            </Route>
            <Route path='*' element={<Navigate to={{ pathname: 'patients' }} replace />} />
        </Routes>
    );
}
