import { useCallback, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import classNames from 'classnames';

import { Patient } from 'contrib/aidbox';
import { logout } from 'services/auth';
import { formatPatientName } from 'utils/patient';

import { home, logo, logout as logoutIcon, print } from './images';
import s from './Header.module.css';

export interface HeaderProps {
    onLogout?: () => void;
}

export function Header(props: HeaderProps) {
    const { onLogout: onLogoutCallback } = props;

    const [patientInFocus, setPatientInFocus] = useState<Patient | undefined>(undefined);

    const onLogout = useCallback(async () => logout().then(onLogoutCallback), [onLogoutCallback]);

    return (
        <div className={s.container}>
            <div className={s.attributes}>
                <div className={s.logoContainer}>
                    <img src={logo} alt='logo' />
                </div>
                <div className={s.text}>juli Health</div>
            </div>
            <div className={s.header}>
                <div className={s.headerTabs}>
                    <div className={classNames(s.headerTab)}>
                        <img src={home} alt='home page' />
                    </div>
                    <Link
                        className={classNames(s.headerTab, { [s.headerTabActive]: patientInFocus === undefined })}
                        to={'/epic/patients'}
                    >
                        <div className={s.headerTabLabel}>Patients</div>
                    </Link>
                    {patientInFocus ? (
                        <Link
                            className={classNames(s.headerTab, s.headerTabActive)}
                            to={`/epic/patient/${patientInFocus.id}`}
                        >
                            <div className={s.headerTabLabel}>{formatPatientName(patientInFocus)}</div>
                        </Link>
                    ) : null}
                </div>
                <div className={s.headerControls}>
                    <button type='button' className={s.headerControl}>
                        <img src={print} className={s.headerControlIcon} alt='print the page' />
                        Print
                    </button>
                    <button type='button' onClick={onLogout} className={s.headerControl}>
                        <img src={logoutIcon} className={s.headerControlIcon} alt='logout' />
                        Log out
                    </button>
                </div>
            </div>
            <Outlet context={[patientInFocus, setPatientInFocus]} />
        </div>
    );
}
