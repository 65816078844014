import moment from 'moment';

import { AidboxResource } from 'contrib/aidbox';

export function hasEffectiveDate(effectiveDate: moment.Moment | undefined, date: moment.Moment) {
    return Boolean(effectiveDate && effectiveDate.isSame(date, 'day'));
}

export function mapMeasurementsHistoryToPeriod<T, R extends AidboxResource>(
    history: R[],
    reducer: (acc: T, measurement: R, date: moment.Moment) => T,
    dateExtractor: (measurement: R) => moment.Moment | undefined,
    options: { periodDays: number; defaultValue: T; endDate?: moment.Moment },
): Array<T> {
    const periodBefore = Array.from(Array(options.periodDays).keys())
        .reverse()
        .map((daysBefore) => (options.endDate?.clone() ?? moment()).startOf('day').subtract(daysBefore, 'days'));

    return history.reduce((periodMeasurements, measurement) => {
        const dayOfThePeriod = periodBefore.findIndex((day) => hasEffectiveDate(dateExtractor(measurement), day));

        if (dayOfThePeriod !== -1) {
            periodMeasurements[dayOfThePeriod] = reducer(
                periodMeasurements[dayOfThePeriod],
                measurement,
                periodBefore[dayOfThePeriod].clone(),
            );
        }

        return periodMeasurements;
    }, Array(options.periodDays).fill(options.defaultValue));
}
