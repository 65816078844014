import { ChartReferenceNorms } from 'utils/chart';

export function calculateReferenceNormsGradient(
    canvas: HTMLCanvasElement,
    reference: ChartReferenceNorms,
    rangeUpperBound: number,
) {
    const gradient = canvas.getContext('2d')?.createLinearGradient(0, canvas.getBoundingClientRect().height, 0, 0);

    if (reference.good !== undefined && reference.good <= rangeUpperBound) {
        gradient?.addColorStop(reference.good / rangeUpperBound, '#00D6B2');
    }

    if (reference.normal !== undefined && reference.normal <= rangeUpperBound) {
        gradient?.addColorStop(reference.normal / rangeUpperBound, '#969BDE');
    }

    if (reference.bad !== undefined && reference.bad <= rangeUpperBound) {
        gradient?.addColorStop(reference.bad / rangeUpperBound, '#E03126');
    }

    return gradient;
}
