import { useState } from 'react';
import { useService } from 'aidbox-react/lib/hooks/service';
import { notAsked, RemoteData, success } from 'aidbox-react/lib/libs/remoteData';
import { mapSuccess, service } from 'aidbox-react/lib/services/service';
import moment from 'moment';

import { Observation } from 'contrib/aidbox';
import { extractMedicationsMeasurements } from 'components/Medications/utils';
import { extractSleepMeasurements } from 'components/Sleep/utils';
import {
    extractWeatherHistoryAirPressureMeasurements,
    extractWeatherHistoryHumidityMeasurements,
    extractWeatherHistoryTemperatureMeasurements,
} from 'utils/weather';
import { formatTimeScale, periodDays, PeriodDuration } from 'utils/chart';
import {
    compareEffectiveDate,
    findObservationComponent,
    getObservationCategory,
    getObservationCode,
    mapObservationsToPeriod,
} from 'utils/observation';
import { PollenRisk, numericPollenRisk, formatPollenRisk } from 'utils/pollen';

import { AdditionalInformationChartData, ADDITIONAL_INFORMATION_TABS, AdditionalInformationTag } from './utils';
import { PatientHistoryData, PatientDetailsDataResponse, PatientDetailsPeriod, PatientInfo } from './types';

export function extractMeasurements(
    observations: Observation[],
    reducer: (acc: number | undefined, observation: Observation) => number | undefined,
    period: PeriodDuration,
    endDate?: moment.Moment,
): Array<number | undefined> {
    return mapObservationsToPeriod<number | undefined>(
        observations,
        (acc: number | undefined, observation: Observation) => reducer(acc, observation),
        { periodDays: periodDays(period), defaultValue: undefined, endDate },
    );
}

export function usePatientDetails(
    patientId: string,
    period: { duration: PeriodDuration; page: number },
): {
    period: PatientDetailsPeriod;
    patientInformationResponse: RemoteData<PatientInfo>;
    patientHistoryResponse: RemoteData<PatientHistoryData>;
} {
    const periodEndDate = moment().subtract(periodDays(period.duration) * (period.page - 1), 'days');
    const periodStartDate = periodEndDate.clone().subtract(periodDays(period.duration) - 1, 'days');

    const [patientInformationResponse, setPatientInformation] = useState<RemoteData<PatientInfo>>(notAsked);

    const [patientHistoryResponse] = useService(async () => {
        return mapSuccess(
            await service<PatientDetailsDataResponse>({
                url: `/epic/sandbox/Patient/$info?duration=${period.duration}&page=${period.page}`,
                params: {
                    _id: patientId,
                },
                method: 'GET',
            }),
            (patientDetails): PatientHistoryData => {
                setPatientInformation(success(patientDetails.patient));

                return {
                    medications: [
                        {
                            label: 'Medications',
                            data: extractMedicationsMeasurements(
                                patientDetails.medication_compliance,
                                period.duration,
                                periodEndDate,
                            ),
                        },
                    ],
                    measurements: {
                        sleep: [
                            {
                                label: 'Time asleep, hours',
                                chartType: 'bar',
                                color: '#969BDE',
                                data: extractSleepMeasurements(
                                    patientDetails.observations.filter(
                                        (observation) => getObservationCode(observation) === 'time-asleep',
                                    ),
                                    period.duration,
                                    periodEndDate,
                                ),
                                scale: (minutes: number) => minutes / 60,
                                format: (minutes: number | undefined) =>
                                    minutes !== undefined ? formatTimeScale(minutes) : undefined,
                            },
                            {
                                label: 'Time in bed, hours',
                                chartType: 'bar',
                                color: '#02108A',
                                data: extractSleepMeasurements(
                                    patientDetails.observations.filter(
                                        (observation) => getObservationCode(observation) === 'time-in-bed',
                                    ),
                                    period.duration,
                                    periodEndDate,
                                ),
                                scale: (minutes: number) => minutes / 60,
                                format: (minutes: number | undefined) =>
                                    minutes !== undefined ? formatTimeScale(minutes) : undefined,
                            },
                        ],
                        activity: [
                            {
                                label: 'Steps',
                                chartType: 'line',
                                reference: { bad: 0, normal: 3000, good: 5000 },
                                data: extractMeasurements(
                                    patientDetails.observations.filter(
                                        (observation) => getObservationCode(observation) === 'steps-count',
                                    ),
                                    (acc, observation) =>
                                        observation.value?.integer !== undefined
                                            ? (acc ?? 0) + observation.value.integer
                                            : acc,
                                    period.duration,
                                    periodEndDate,
                                ),
                            },
                            {
                                label: 'Calories burned, kcal',
                                chartType: 'bar',
                                reference: { bad: 0, normal: 300, good: 500 },
                                data: extractMeasurements(
                                    patientDetails.observations.filter(
                                        (observation) =>
                                            getObservationCode(observation) === 'active-energy-burned' ||
                                            getObservationCode(observation) === 'basal-energy-burned',
                                    ),
                                    (acc, observation) =>
                                        observation.value !== undefined
                                            ? (acc ?? 0) + Math.round(observation.value.Quantity!.value!)
                                            : acc,
                                    period.duration,
                                    periodEndDate,
                                ),
                            },
                            {
                                label: 'Workout, min',
                                chartType: 'bar',
                                reference: { bad: 0, normal: 10, good: 20 },
                                data: extractMeasurements(
                                    patientDetails.observations.filter(
                                        (observation) => getObservationCode(observation) === 'workout',
                                    ),
                                    (acc, observation) => {
                                        const workoutDuration = observation.component?.find(
                                            (component) => getObservationCode(component) === 'workout-duration',
                                        )?.value?.Quantity?.value;

                                        return workoutDuration !== undefined
                                            ? Math.round((acc ?? 0) + workoutDuration)
                                            : undefined;
                                    },
                                    period.duration,
                                    periodEndDate,
                                ),
                                format: (minutes: number | undefined) =>
                                    minutes !== undefined ? formatTimeScale(minutes) : undefined,
                            },
                        ],
                        hrv: [
                            {
                                label: 'HRV',
                                chartType: 'line',
                                range: { min: 0, max: 120 },
                                reference: { bad: 0, normal: 30, good: 50 },
                                data: extractMeasurements(
                                    patientDetails.observations.filter(
                                        (observation) => getObservationCode(observation) === 'heart-rate-variability',
                                    ),
                                    (_, observation) =>
                                        observation.value?.Quantity?.value !== undefined
                                            ? Math.round(observation.value?.Quantity?.value)
                                            : undefined,
                                    period.duration,
                                    periodEndDate,
                                ),
                            },
                        ],
                        weather: [
                            {
                                label: 'Temperature, °C',
                                chartType: 'line',
                                data: extractWeatherHistoryTemperatureMeasurements(
                                    patientDetails.weather,
                                    period.duration,
                                    periodEndDate,
                                ),
                            },
                            {
                                label: 'Humidity, %',
                                chartType: 'line',
                                data: extractWeatherHistoryHumidityMeasurements(
                                    patientDetails.weather,
                                    period.duration,
                                    periodEndDate,
                                ),
                            },
                            {
                                label: 'Air Pressure, mmHg',
                                chartType: 'line',
                                data: extractWeatherHistoryAirPressureMeasurements(
                                    patientDetails.weather,
                                    period.duration,
                                    periodEndDate,
                                ),
                            },
                        ],
                        phq8: [
                            {
                                label: 'PHQ8',
                                chartType: 'line',
                                range: { min: 0, max: 24 },
                                reference: { bad: 24, normal: 16, good: 0 },
                                data: extractMeasurements(
                                    patientDetails.observations.filter(
                                        (observation) =>
                                            getObservationCode(observation) ===
                                            'bi-weekly-depression-questionnaire-score',
                                    ),
                                    (_, observation) => observation.value?.integer,
                                    period.duration,
                                    periodEndDate,
                                ),
                            },
                        ],
                        mood: [
                            {
                                label: 'Mood',
                                chartType: 'line',
                                range: { min: 0, max: 4 },
                                reference: { bad: 0, normal: 2, good: 3 },
                                data: extractMeasurements(
                                    patientDetails.observations
                                        .filter(
                                            (observation) =>
                                                getObservationCode(observation) === 'daily-questionnaire-mood',
                                        )
                                        .sort(compareEffectiveDate),
                                    (_, observation) => {
                                        const mood = observation.value?.CodeableConcept?.coding?.[0]?.code;
                                        const mapping: { [x: string]: number } = {
                                            'very-bad': 0,
                                            bad: 1,
                                            good: 2,
                                            'very-good': 3,
                                            excellent: 4,
                                        };

                                        return mood !== undefined && mood in mapping ? mapping[mood] : undefined;
                                    },
                                    period.duration,
                                    periodEndDate,
                                ),
                                format: (mood) =>
                                    mood !== undefined ? ['Bad', 'Bad', 'Neutral', 'Good', 'Good'][mood] : undefined,
                            },
                        ],
                        airPollution: [
                            {
                                label: 'Air pollution',
                                chartType: 'line',
                                data: extractMeasurements(
                                    patientDetails.observations.filter(
                                        (observation) => getObservationCode(observation) === 'air-quality',
                                    ),
                                    (_, observation) => observation.value?.integer,
                                    period.duration,
                                    periodEndDate,
                                ),
                            },
                        ],
                        pollen: [
                            { label: 'Grass', code: 'risk-grass' },
                            { label: 'Tree', code: 'risk-tree' },
                            { label: 'Weeds', code: 'risk-weed' },
                        ].map(
                            ({ label, code }): AdditionalInformationChartData => ({
                                label,
                                chartType: 'bar',
                                range: { min: 0, max: 4 },
                                data: extractMeasurements(
                                    patientDetails.observations.filter(
                                        (observation) => getObservationCode(observation) === 'air-quality-pollen',
                                    ),
                                    (_, observation) => {
                                        const risk = findObservationComponent(observation, code)?.value?.string;

                                        return risk !== undefined ? numericPollenRisk(risk as PollenRisk) : undefined;
                                    },
                                    period.duration,
                                    periodEndDate,
                                ),
                                format: (risk: number | undefined) =>
                                    risk !== undefined ? formatPollenRisk(risk) : undefined,
                                formatRange: (tick: string | number) => {
                                    if (typeof tick === 'number') {
                                        return formatPollenRisk(tick) ?? '';
                                    } else {
                                        return undefined;
                                    }
                                },
                            }),
                        ),
                        weight: [
                            {
                                label: 'Weight, kg',
                                chartType: 'line',
                                data: extractMeasurements(
                                    patientDetails.observations.filter(
                                        (observation) => getObservationCode(observation) === 'weight',
                                    ),
                                    (_, observation) => observation.value?.Quantity!.value,
                                    period.duration,
                                    periodEndDate,
                                ),
                            },
                        ],
                        individualTracking: patientDetails.individial_tracking.map(
                            (trackable): AdditionalInformationChartData => ({
                                label: trackable.title,
                                chartType: 'bar',
                                range: {
                                    min: trackable.referenceRange?.min ?? 0,
                                    max: trackable.referenceRange?.max ?? 10,
                                },
                                data: extractMeasurements(
                                    patientDetails.observations.filter(
                                        (observation) =>
                                            getObservationCategory(observation) === 'individual-tracking' &&
                                            observation.focus?.[0]?.id === trackable.id,
                                    ),
                                    (_, observation) => observation.value?.integer,
                                    period.duration,
                                    periodEndDate,
                                ),
                            }),
                        ),
                    },
                };
            },
        );
    }, [period.duration, period.page]);

    return {
        period: { duration: period.duration, startDate: periodStartDate, endDate: periodEndDate },
        patientInformationResponse,
        patientHistoryResponse,
    };
}

export function selectActiveTabData(
    patientDetails: PatientHistoryData,
    activeTab?: AdditionalInformationTag,
): [AdditionalInformationChartData[], AdditionalInformationTag] {
    const activeTabTag =
        activeTab && activeTab in patientDetails.measurements ? activeTab : ADDITIONAL_INFORMATION_TABS[0].tag;

    return [patientDetails.measurements[activeTabTag], activeTabTag];
}
