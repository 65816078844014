import ContentLoader from 'react-content-loader';

import s from './ActivityIndicator.module.css';

export function ActivityIndicator() {
    return (
        <div className={s.container}>
            <ContentLoader speed={2} width={600} height={500} backgroundColor='#eef6fd' foregroundColor='#e1eef9'>
                <rect x={0} y={0} width={600} height={3} />
                <rect x={0} y={0} width={3} height={40} />
                <rect x={597} y={0} width={3} height={40} />
                <rect x={0} y={40} width={600} height={3} />

                <rect x={10} y={10} rx={5} ry={5} width={200} height={25} />
                <rect x={250} y={10} rx={5} ry={5} width={100} height={25} />
                <rect x={360} y={10} rx={5} ry={5} width={100} height={25} />
                <rect x={470} y={10} rx={5} ry={5} width={100} height={25} />

                <rect x={0} y={50} width={600} height={3} />
                <rect x={0} y={50} width={3} height={400} />
                <rect x={597} y={50} width={3} height={400} />
                <rect x={0} y={450} width={600} height={3} />

                <rect x={10} y={60} rx={5} ry={5} width={580} height={50} />
                <rect x={10} y={120} rx={5} ry={5} width={580} height={50} />

                <rect x={10} y={180} width={580} height={3} />
                <rect x={10} y={180} width={3} height={200} />
                <rect x={587} y={180} width={3} height={200} />
                <rect x={10} y={380} width={580} height={3} />
            </ContentLoader>
        </div>
    );
}
