import { useEffect, useRef } from 'react';
import { Chart, BarController, BarElement, TooltipItem } from 'chart.js';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

import { CHART_YAXIS_LEGEND_WIDHT } from 'components/utils';
import { PeriodDuration, sameRange } from 'utils/chart';

import { BarChartComponent } from './types';
import { periodBarPercentage } from './utils';

const DEFAULT_COLORS = ['#1178B2', '#6268CA', '#657389', '#B381B3', '#D46A43'];

export interface BarChartProps {
    period: PeriodDuration;
    startDate: moment.Moment;
    components: BarChartComponent[];
    stacked?: boolean;
    ticks?: number;
}

Chart.register(BarController, BarElement);

export function BarChart(props: BarChartProps) {
    const { period, startDate, components, stacked, ticks = 5 } = props;

    const canvasId = useRef(uuidv4()).current;
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const chartRef = useRef<Chart>();

    useEffect(() => {
        const data = {
            labels: (components[0] ?? []).data.map((_, daysSinceStart) =>
                startDate.clone().add(daysSinceStart, 'days').format('D MMM'),
            ),
            datasets: components.map(({ label, data, color, scale, format }, index) => ({
                label,
                data: data.map((p) => (p === undefined ? NaN : scale?.(p) ?? p)),
                backgroundColor: color ?? DEFAULT_COLORS[index % DEFAULT_COLORS.length],
                spanGaps: true,
                barPercentage: periodBarPercentage(period),
                formatted: data.map((measurement) => format?.(measurement)),
            })),
        };

        if (canvasRef.current && !chartRef.current) {
            chartRef.current = new Chart(canvasRef.current, {
                type: 'bar',
                data: data,
                options: {
                    interaction: {
                        intersect: false,
                        mode: 'index',
                    },
                    scales: {
                        y: {
                            min: components[0]?.range?.min,
                            max: components[0]?.range?.max,
                            afterFit: function (scaleInstance) {
                                scaleInstance.width = CHART_YAXIS_LEGEND_WIDHT;
                            },
                            ticks: {
                                count: ticks,
                                callback: components[0]?.formatRange,
                            },
                        },
                        y1: {
                            min: components[1]?.range?.min ?? 0,
                            max: components[1]?.range?.max ?? 0,
                            position: 'right',
                            ticks: {
                                count: ticks,
                            },
                            display: !sameRange(components[0]?.range, components[1]?.range),
                            afterFit: function (scaleInstance) {
                                scaleInstance.width = CHART_YAXIS_LEGEND_WIDHT;
                            },
                        },
                        x: {
                            stacked,
                        },
                    },
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            align: 'end',
                        },
                        tooltip: {
                            callbacks: {
                                label: (context: TooltipItem<'bar'>) => {
                                    const dataset = data.datasets[context.datasetIndex];
                                    const measurement = dataset.data[context.dataIndex];

                                    if (isNaN(measurement)) {
                                        return `${context.dataset.label}: Not available`;
                                    }

                                    const formattedValue =
                                        dataset.formatted[context.dataIndex] ?? context.formattedValue;

                                    return `${context.dataset.label}: ${formattedValue}`;
                                },
                            },
                        },
                    },
                },
            });
        }
    }, [period, startDate, components, stacked, ticks]);

    return <canvas id={canvasId} ref={canvasRef} />;
}
