import { useEffect, useRef } from 'react';
import {
    CategoryScale,
    Chart,
    LinearScale,
    LineController,
    LineElement,
    PointElement,
    Legend,
    Tooltip,
    ChartDataset,
} from 'chart.js';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

import { CHART_YAXIS_LEGEND_WIDHT } from 'components/utils';
import { rangeUpperBound, sameRange } from 'utils/chart';

import { LinarChartComponent } from './types';
import { calculateReferenceNormsGradient } from './utils';

export interface LinearChartProps {
    startDate: moment.Moment;
    components: LinarChartComponent[];
}

Chart.register(LineController, LineElement, PointElement, LinearScale, CategoryScale, Legend, Tooltip);

export function LinearChart(props: LinearChartProps) {
    const { startDate, components } = props;

    const canvasId = useRef(uuidv4()).current;
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const chartRef = useRef<Chart>();

    useEffect(() => {
        if (canvasRef.current && !chartRef.current) {
            const linearDataset: ChartDataset[] = components.map(({ label, data, reference, range }, index) => ({
                type: 'line',
                label,
                data: data.map((p) => (p === undefined ? NaN : p)),
                fill: false,
                borderColor: reference
                    ? calculateReferenceNormsGradient(canvasRef.current!, reference, rangeUpperBound(data, range))
                    : 'green',
                borderWidth: 2,
                pointRadius: 4,
                pointBackgroundColor: 'white',
                cubicInterpolationMode: 'monotone',
                segment: {
                    borderDash: (ctx) => (ctx.p0.skip || ctx.p1.skip ? [2, 4] : undefined),
                },
                spanGaps: true,
                yAxisID: index === 0 ? 'y' : 'y1',
            }));

            // It's using to display linear chart's dots between grid lines
            const barOffsetTrickDataset: ChartDataset[] = [
                {
                    type: 'bar',
                    data: [NaN],
                    hidden: true,
                },
            ];

            const datasets = [...linearDataset, ...barOffsetTrickDataset];

            chartRef.current = new Chart(canvasRef.current, {
                type: 'scatter',
                data: {
                    labels: (components[0] ?? []).data.map((_, daysSinceStart) =>
                        startDate.clone().add(daysSinceStart, 'days').format('D MMM'),
                    ),
                    datasets,
                },
                options: {
                    interaction: {
                        intersect: false,
                        mode: 'index',
                    },
                    scales: {
                        y: {
                            min: components[0]?.range?.min,
                            max: components[0]?.range?.max,
                            ticks: {
                                count: 5,
                            },
                            afterFit: function (scaleInstance) {
                                scaleInstance.width = CHART_YAXIS_LEGEND_WIDHT;
                            },
                        },
                        y1: {
                            min: components[1]?.range?.min ?? 0,
                            max: components[1]?.range?.max ?? 0,
                            position: 'right',
                            ticks: {
                                count: 5,
                            },
                            grid: {
                                drawOnChartArea: false,
                            },
                            display: !sameRange(components[0]?.range, components[1]?.range),
                            afterFit: function (scaleInstance) {
                                scaleInstance.width = CHART_YAXIS_LEGEND_WIDHT;
                            },
                        },
                    },
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            align: 'end',
                            labels: {
                                filter: (legendItem, _) =>
                                    legendItem.datasetIndex !== undefined
                                        ? datasets[legendItem.datasetIndex].type === 'line'
                                        : false,
                            },
                        },
                    },
                },
            });
        }
    }, [startDate, components]);

    return <canvas id={canvasId} ref={canvasRef} />;
}
