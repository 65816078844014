import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { parseFHIRDateTime } from 'utils/date';
import { formatPatientName } from 'utils/patient';

import { PatientListData } from '../hooks';
import { HealthStatus } from './HealthStatus';
import { select, sort, right } from './images';
import s from './PatientsTable.module.css';

export function PatientsTable(props: { patientsList: PatientListData[] }) {
    return (
        <div className={s.table}>
            <div className={s.header}>
                <div className={classNames(s.column, s.patientColumn)}>
                    <img src={select} className={s.cellLeftIcon} alt='' />
                    <div className={classNames(s.headerText, s.patientColumnHeaderText)}>Patient</div>
                </div>
                <div className={classNames(s.column, s.headerColumnBorder)}>
                    <div className={s.headerText}>Health status</div>
                    <img src={sort} className={s.cellRightIcon} alt='' />
                </div>
                <div className={classNames(s.column, s.headerColumnBorder)}>
                    <div className={s.headerText}>Next contact date</div>
                    <img src={sort} className={s.cellRightIcon} alt='' />
                </div>
                <div className={classNames(s.column, s.headerColumnBorder)}>
                    <div className={s.headerText}>Next appointment</div>
                    <img src={sort} className={s.cellRightIcon} alt='' />
                </div>
                <div className={classNames(s.column, s.headerColumnBorder)}>
                    <div className={s.headerText}>Med. compliance, %</div>
                    <img src={sort} className={s.cellRightIcon} alt='' />
                </div>
                <div className={classNames(s.column, s.headerColumnBorder)}>
                    <div className={s.headerText}>Wellness score</div>
                    <img src={sort} className={s.cellRightIcon} alt='' />
                </div>
            </div>
            <div className={s.list}>
                {props.patientsList.map(
                    (
                        {
                            patient,
                            juli_score,
                            medication_compliance_percantage,
                            next_contact_date,
                            next_appointment_date,
                        },
                        index,
                    ) => (
                        <div className={s.row} key={index}>
                            <div className={classNames(s.column, s.patientColumn)}>
                                <img src={select} className={s.cellLeftIcon} alt='' />
                                {patient.id ? (
                                    <Link className={s.link} to={`/epic/patient/${patient.id}`}>
                                        {formatPatientName(patient)}
                                    </Link>
                                ) : (
                                    <div className={s.link}>{formatPatientName(patient)}</div>
                                )}
                            </div>
                            <div className={classNames(s.column, s.healthStatusColumn)}>
                                {juli_score?.value?.integer !== undefined ? (
                                    <HealthStatus juliScore={juli_score.value.integer} />
                                ) : (
                                    <div className={s.columnText}>N/A</div>
                                )}
                            </div>
                            <div className={classNames(s.column)}>
                                <div className={s.columnText}>
                                    {next_contact_date
                                        ? parseFHIRDateTime(next_contact_date).format('MM-DD-YY')
                                        : 'N/A'}
                                </div>
                            </div>
                            <div className={classNames(s.column)}>
                                <div className={s.columnText}>
                                    {next_appointment_date
                                        ? parseFHIRDateTime(next_appointment_date).format('MM-DD-YY')
                                        : 'N/A'}
                                </div>
                            </div>
                            <div className={classNames(s.column)}>
                                <div className={s.columnText}>{medication_compliance_percantage ?? 'N/A'}</div>
                            </div>
                            <div className={classNames(s.column)}>
                                <div className={s.columnText}>{juli_score?.value?.integer ?? 'N/A'}</div>
                            </div>
                        </div>
                    ),
                )}
            </div>
            <div className={s.footerNavigation}>
                <div className={classNames(s.footerNavigationText, s.footerSummary)}>
                    1-{props.patientsList.length} of {props.patientsList.length} items
                </div>
                <button className={s.footerNavigationElement}>
                    <img
                        src={right}
                        className={classNames(s.footerNavigationIconBack, s.footerNavigationIconInactive)}
                        alt='previous page'
                    />
                </button>
                <button className={classNames(s.footerNavigationElement, s.footerNavigationElementActive)}>
                    <div className={classNames(s.footerNavigationText, s.footerNavigationTextActive)}>1</div>
                </button>
                <button className={s.footerNavigationElement}>
                    <img src={right} className={s.footerNavigationIconInactive} alt='next page' />
                </button>
            </div>
        </div>
    );
}
