import classNames from 'classnames';

import s from './HealthStatus.module.css';

export function HealthStatus(props: { juliScore: number }) {
    const { juliScore } = props;

    return (
        <div
            className={classNames(s.statusIndicator, {
                [s.good]: juliScore > 60,
                [s.normal]: juliScore > 30 && juliScore <= 60,
                [s.bad]: juliScore <= 30,
            })}
        />
    );
}
