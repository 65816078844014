import classNames from 'classnames';

import { RenderRemoteData } from 'components/RenderRemoteData';

import { PatientsTable } from './PatientsTable';
import { usePatientList } from './hooks';
import { back, forward } from './images';
import s from './PatientList.module.css';

export interface PatientListProps {}

export function PatientList(_props: PatientListProps) {
    const { patientsListResponse } = usePatientList();

    return (
        <div className={s.content}>
            <div className={s.header}>
                <div className={s.navigation}>
                    <div className={s.navigationControls}>
                        <button className={s.navigationControl}>
                            <img src={back} className={s.navigationControlIcon} alt='previous page' />
                        </button>
                        <button className={s.navigationControl}>
                            <img src={forward} className={s.navigationControlIcon} alt='next page' />
                        </button>
                    </div>
                </div>
                <div className={s.title}>
                    <div className={s.titleHeaderText}>Patients list</div>
                </div>
            </div>
            <div className={s.mainContent}>
                <div className={s.navigation}>
                    <div className={classNames(s.navigationSection)}>
                        <div className={s.navigationSectionText}>Dashboard</div>
                    </div>
                    <div className={classNames(s.navigationSection, s.navigationSectionActive)}>
                        <div className={s.navigationSectionText}>Patients</div>
                    </div>
                    <div className={classNames(s.navigationSection)}>
                        <div className={s.navigationSectionText}>juli Health Settings</div>
                    </div>
                </div>
                <div className={s.details}>
                    <RenderRemoteData remoteData={patientsListResponse}>
                        {(patientListData) => <PatientsTable patientsList={patientListData} />}
                    </RenderRemoteData>
                </div>
            </div>
        </div>
    );
}
