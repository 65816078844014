import { resetInstanceToken, setInstanceToken } from 'aidbox-react/lib/services/instance';
import { mapSuccess, service } from 'aidbox-react/lib/services/service';
import { Token } from 'aidbox-react/src/services/token';

function saveTokenToLocalStorage(token: Token) {
    window.localStorage.setItem('session', JSON.stringify(token));
}

export function restoreToken() {
    const formattedToken = window.localStorage.getItem('session');
    const token = formattedToken ? (JSON.parse(formattedToken) as Token) : undefined;

    if (token) {
        setInstanceToken(token);
    }

    return token;
}

function clearLocalStorageToken() {
    window.localStorage.removeItem('session');
}

export interface LoginBody {
    email: string;
    password: string;
}

export async function login(data: LoginBody) {
    return mapSuccess(
        await service<Token>({
            url: '/auth/token',
            method: 'POST',
            data: {
                username: data.email,
                password: data.password,
                client_id: 'SPA',
                client_secret: '123456',
                grant_type: 'password',
            },
        }),
        (token) => {
            saveTokenToLocalStorage(token);
            setInstanceToken(token);
            return token;
        },
    );
}

export async function logout() {
    return mapSuccess(
        await service({
            url: '/Session',
            method: 'DELETE',
        }),
        () => {
            resetInstanceToken();
            clearLocalStorageToken();
        },
    );
}
