import { useRef, useEffect } from 'react';
import { Moment } from 'moment';
import { Chart, ChartDataset, ScriptableLineSegmentContext, TooltipItem } from 'chart.js';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';

import { AdditionalInformationChartData } from 'containers/Epic/PatientDetails/utils';
import { calculateReferenceNormsGradient } from 'components/LinearChart/utils';
import { MedicationChartComponentProps, MedicationStatus } from 'components/Medications/types';
import { isAnyMedicationCompleted } from 'components/Medications/utils';
import { CHART_YAXIS_LEGEND_WIDHT } from 'components/utils';
import { periodBarPercentage } from 'components/BarChart/utils';
import { PeriodDuration, rangeUpperBound } from 'utils/chart';

import { bad, excellent, good, normal, terrible } from './images';
import s from './MainChart.module.css';

interface MainChartProps {
    period: PeriodDuration;
    startDate: Moment;
    phq8Components: AdditionalInformationChartData;
    moodComponents: AdditionalInformationChartData;
    medicationsComponents: MedicationChartComponentProps;
}

export function MainChart(props: MainChartProps) {
    const { period, startDate, phq8Components, moodComponents, medicationsComponents } = props;

    const canvasId = useRef(uuidv4()).current;
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const chartRef = useRef<Chart>();

    useEffect(() => {
        if (canvasRef.current && !chartRef.current) {
            const moodDatasets: ChartDataset<'line', number[]> & { formatted: Array<string | undefined> } = {
                type: 'line',
                label: moodComponents.label,
                order: 2,
                data: moodComponents.data.map((p) => (p === undefined ? NaN : p)),
                fill: false,
                borderColor: moodComponents.reference
                    ? calculateReferenceNormsGradient(
                          canvasRef.current!,
                          moodComponents.reference,
                          rangeUpperBound(moodComponents.data, moodComponents.range),
                      )
                    : 'green',
                borderWidth: 2,
                pointRadius: 4,
                pointBackgroundColor: 'white',
                cubicInterpolationMode: 'monotone',
                segment: {
                    borderDash: (ctx: ScriptableLineSegmentContext) =>
                        ctx.p0.skip || ctx.p1.skip ? [2, 4] : undefined,
                },
                spanGaps: true,
                yAxisID: 'y',
                formatted: moodComponents.data.map((measurement) => moodComponents.format?.(measurement)),
            };

            const phq8Datasets: ChartDataset<'line', number[]> = {
                type: 'line',
                label: phq8Components.label,
                order: 1,
                data: phq8Components.data.map((p) => (p === undefined ? NaN : p)),
                fill: false,
                borderColor: phq8Components.reference
                    ? calculateReferenceNormsGradient(
                          canvasRef.current!,
                          phq8Components.reference,
                          rangeUpperBound(phq8Components.data, phq8Components.range),
                      )
                    : 'green',
                borderWidth: 2,
                pointRadius: 6,
                pointBackgroundColor: 'white',
                pointBorderColor: 'black',
                pointStyle: 'triangle',
                cubicInterpolationMode: 'monotone',
                segment: {
                    borderDash: (ctx: ScriptableLineSegmentContext) =>
                        ctx.p0.skip || ctx.p1.skip ? [2, 4] : undefined,
                },
                spanGaps: true,
                yAxisID: 'y1',
            };

            const medicationsDatasets: ChartDataset<'bar', number[]> = {
                type: 'bar',
                label: medicationsComponents.label,
                order: 3,
                data: medicationsComponents.data.map((medicationStatuses) =>
                    isAnyMedicationCompleted(medicationStatuses) ? 0.5 : NaN,
                ),
                backgroundColor: '#969BDE',
                pointStyle: 'rect',
                barPercentage: periodBarPercentage(period),
                minBarLength: 16,
                yAxisID: 'y',
            };

            chartRef.current = new Chart(canvasRef.current, {
                type: 'scatter',
                data: {
                    labels: (moodComponents ?? []).data.map((_, daysSinceStart) =>
                        startDate.clone().add(daysSinceStart, 'days').format('D MMM'),
                    ),
                    datasets: [moodDatasets, phq8Datasets, medicationsDatasets],
                },
                options: {
                    interaction: {
                        intersect: false,
                        mode: 'index',
                    },
                    scales: {
                        y: {
                            min: moodComponents.range?.min,
                            max: moodComponents.range?.max,
                            ticks: {
                                count: 5,
                                display: false,
                            },
                            afterFit: function (scaleInstance) {
                                scaleInstance.width = CHART_YAXIS_LEGEND_WIDHT;
                            },
                            reverse: false,
                        },
                        y1: {
                            min: phq8Components.range?.min ?? 0,
                            max: phq8Components.range?.max ?? 0,
                            position: 'right',
                            grid: {
                                drawOnChartArea: false,
                            },
                            ticks: {
                                count: 5,
                            },
                            afterFit: function (scaleInstance) {
                                scaleInstance.width = CHART_YAXIS_LEGEND_WIDHT;
                            },
                            reverse: true,
                        },
                    },
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            align: 'end',
                            labels: {
                                usePointStyle: true,
                            },
                        },
                        tooltip: {
                            callbacks: {
                                label: (context: TooltipItem<'bar'> | TooltipItem<'line'>) => {
                                    switch (context.dataset.label) {
                                        case medicationsComponents.label:
                                            const medicationStatuses = medicationsComponents.data[context.dataIndex];

                                            return isAnyMedicationCompleted(medicationStatuses)
                                                ? [
                                                      'Medications',
                                                      ...medicationStatuses!.map(
                                                          (ms: MedicationStatus) => `- ${ms.name}: ${ms.status}`,
                                                      ),
                                                  ]
                                                : '';

                                        case moodComponents.label:
                                            const measurement = moodDatasets.data[context.dataIndex];

                                            if (isNaN(measurement)) {
                                                return `${context.dataset.label}: Not available`;
                                            }

                                            const formattedValue =
                                                moodDatasets.formatted[context.dataIndex] ?? context.formattedValue;

                                            return `${context.dataset.label}: ${formattedValue}`;

                                        default:
                                            return `${context.dataset.label}: ${context.formattedValue}`;
                                    }
                                },
                            },
                        },
                    },
                },
            });
        }
    }, [period, startDate, moodComponents, phq8Components, medicationsComponents]);

    return (
        <div className={s.container}>
            <div
                className={classNames(s.moodScale, {
                    [s.monthMoodScale]: period === 'month',
                    [s.threeMonthsMoodScale]: period === 'three-months',
                })}
            >
                {(period === 'two-weeks'
                    ? [
                          [excellent, 'excellent mood'],
                          [good, 'good mood'],
                          [normal, 'normal mood'],
                          [bad, 'bad mood'],
                          [terrible, 'terrible mood'],
                      ]
                    : [
                          [excellent, 'excellent mood'],
                          [normal, 'normal mood'],
                          [terrible, 'terrible mood'],
                      ]
                ).map(([icon, label], index) => (
                    <img key={index} src={icon} className={s.moodIcon} alt={label} />
                ))}
            </div>
            <div className={s.chart}>
                <canvas id={canvasId} ref={canvasRef} />
            </div>
        </div>
    );
}
