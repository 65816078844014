import { useMemo } from 'react';
import classNames from 'classnames';

import { ActiveTabChart } from './ActiveTabChart';
import { MainChart } from './MainChart';
import { PeriodNavigation, PeriodNavigationProps } from './PeriodNavigation';
import { selectActiveTabData } from './hooks';
import { ADDITIONAL_INFORMATION_TABS, AdditionalInformationTag } from './utils';
import { PatientDetailsPeriod, PatientHistoryData } from './types';
import { pin, toggle } from './images';
import s from './PatientDetails.module.css';

export interface HistoryChartsProps extends PatientHistoryData {
    period: PatientDetailsPeriod;
    activeTab: AdditionalInformationTag | undefined;
    activePage: number;
    pinned: boolean;
    folded: boolean;
    onChangeDetailsTab: (tag: AdditionalInformationTag) => void;
    onChangePeriod: PeriodNavigationProps['onChangePeriod'];
    onPinMainChart: (pinned: boolean) => void;
    onToggleDetails: (folded: boolean) => void;
}

export function HistoryCharts(props: HistoryChartsProps) {
    const {
        measurements,
        medications,
        activeTab,
        activePage,
        period,
        pinned,
        folded,
        onChangeDetailsTab,
        onChangePeriod,
        onPinMainChart,
        onToggleDetails,
    } = props;

    const [activeTabData, activeTabTag] = useMemo(() => selectActiveTabData(props, activeTab), [props, activeTab]);

    return (
        <div className={s.charts}>
            <div className={s.header}>
                <div className={s.titleContainer}>
                    <div className={s.title}>Condition overview</div>
                    <div className={s.period}>
                        {period.startDate.format('D MMM')} – {period.endDate.format('D MMM')}
                    </div>
                </div>
                <PeriodNavigation period={period.duration} page={activePage} onChangePeriod={onChangePeriod} />
            </div>
            <div className={classNames(s.mainChartContainer, { [s.mainChartPinned]: pinned })}>
                <div className={s.mainChartHeader}>
                    <div className={s.select}>Depression</div>
                    <button
                        type='button'
                        onClick={() => onPinMainChart(!pinned)}
                        className={classNames(s.mainChartPinControl, {
                            [s.mainChartPinControlActive]: pinned,
                        })}
                    >
                        <img src={pin} className={s.mainChartPinIcon} alt='pin the main chart' />
                    </button>
                </div>
                <div className={s.paddedChart}>
                    <div className={s.padding} />
                    <div className={s.chart}>
                        <MainChart
                            period={period.duration}
                            startDate={period.startDate}
                            moodComponents={measurements.mood[0]}
                            phq8Components={measurements.phq8[0]}
                            medicationsComponents={medications[0]}
                        />
                    </div>
                    <div className={s.padding} />
                </div>
            </div>
            <div className={s.detailsContainer}>
                <div className={s.controls}>
                    <button
                        type='button'
                        onClick={() => onToggleDetails(!folded)}
                        className={s.detailsVisibilityToggleControl}
                    >
                        <div className={s.select}>Additional data</div>
                        <img
                            src={toggle}
                            className={classNames(s.detailsVisibilityToggleIcon, {
                                [s.detailsVisibilityToggleIconUnfold]: folded,
                            })}
                            alt='toogle details view'
                        />
                    </button>
                    {!folded ? (
                        <div className={s.tabs}>
                            {ADDITIONAL_INFORMATION_TABS.filter(({ tag }) => measurements[tag]?.length).map(
                                ({ label, tag }) => (
                                    <button
                                        key={tag}
                                        type='button'
                                        onClick={() => onChangeDetailsTab(tag)}
                                        className={classNames(s.tab, {
                                            [s.activeTab]: activeTabTag === tag,
                                        })}
                                    >
                                        {label}
                                    </button>
                                ),
                            )}
                        </div>
                    ) : null}
                </div>
                {!folded ? (
                    <div className={s.paddedChart}>
                        <div className={s.padding} />
                        <div className={s.chart}>
                            <ActiveTabChart
                                key={activeTabTag}
                                period={period.duration}
                                activeTab={activeTabData}
                                activeTabTag={activeTabTag}
                                startDate={period.startDate}
                            />
                        </div>
                        <div className={s.padding} />
                    </div>
                ) : null}
            </div>
        </div>
    );
}
