const POLLEN_RISK_LOW = 'Low';
const POLLEN_RISK_MODERATE = 'Moderate';
const POLLEN_RISK_HIGH = 'High';
const POLLEN_RISK_VERY_HIGH = 'Very High';

export type PollenRisk =
    | typeof POLLEN_RISK_LOW
    | typeof POLLEN_RISK_MODERATE
    | typeof POLLEN_RISK_HIGH
    | typeof POLLEN_RISK_VERY_HIGH;

export function numericPollenRisk(risk: PollenRisk): number | undefined {
    const risks: { [key in PollenRisk]: number } = {
        [POLLEN_RISK_LOW]: 1,
        [POLLEN_RISK_MODERATE]: 2,
        [POLLEN_RISK_HIGH]: 3,
        [POLLEN_RISK_VERY_HIGH]: 4,
    };

    return risks[risk];
}

export function formatPollenRisk(risk: number): PollenRisk | undefined {
    return Array.of<PollenRisk | undefined>(
        undefined,
        POLLEN_RISK_LOW,
        POLLEN_RISK_MODERATE,
        POLLEN_RISK_HIGH,
        POLLEN_RISK_VERY_HIGH,
    )[risk];
}
