import { ChartType } from 'chart.js';

import { ChartRange, ChartReferenceNorms } from 'utils/chart';

export const SLEEP_ADDITIONAL_INFORMATION_TAG = 'sleep';
export const ACTIVITY_ADDITIONAL_INFORMATION_TAG = 'activity';
export const HRV_ADDITIONAL_INFORMATION_TAG = 'hrv';
export const PHQ8_ADDITIONAL_INFORMATION_TAG = 'phq8';
export const MOOD_ADDITIONAL_INFORMATION_TAG = 'mood';
export const WEATHER_ADDITIONAL_INFORMATION_TAG = 'weather';
export const AIR_POLLUTION_ADDITIONAL_INFORMATION_TAG = 'airPollution';
export const POLLEN_ADDITIONAL_INFORMATION_TAG = 'pollen';
export const WEIGHT_ADDITIONAL_INFORMATION_TAG = 'weight';
export const INDIVIDUAL_TRACKING_ADDITIONAL_INFORMATION_TAG = 'individualTracking';

export type AdditionalInformationTag =
    | typeof ACTIVITY_ADDITIONAL_INFORMATION_TAG
    | typeof AIR_POLLUTION_ADDITIONAL_INFORMATION_TAG
    | typeof HRV_ADDITIONAL_INFORMATION_TAG
    | typeof PHQ8_ADDITIONAL_INFORMATION_TAG
    | typeof SLEEP_ADDITIONAL_INFORMATION_TAG
    | typeof MOOD_ADDITIONAL_INFORMATION_TAG
    | typeof WEATHER_ADDITIONAL_INFORMATION_TAG
    | typeof WEIGHT_ADDITIONAL_INFORMATION_TAG
    | typeof POLLEN_ADDITIONAL_INFORMATION_TAG
    | typeof INDIVIDUAL_TRACKING_ADDITIONAL_INFORMATION_TAG;

export interface AdditionalInformationChartData {
    label: string;
    chartType: ChartType;
    data: Array<number | undefined>;
    range?: ChartRange;
    reference?: ChartReferenceNorms;
    color?: string;
    scale?: (measurement: number) => number;
    format?: (measurement: number | undefined) => string | undefined;
    formatRange?: (tick: string | number) => string | undefined;
}

export const ADDITIONAL_INFORMATION_TABS: Array<{ label: string; tag: AdditionalInformationTag }> = [
    { label: 'Sleep', tag: SLEEP_ADDITIONAL_INFORMATION_TAG },
    { label: 'Activity', tag: ACTIVITY_ADDITIONAL_INFORMATION_TAG },
    { label: 'Air pollution', tag: AIR_POLLUTION_ADDITIONAL_INFORMATION_TAG },
    { label: 'HRV', tag: HRV_ADDITIONAL_INFORMATION_TAG },
    { label: 'Weather', tag: WEATHER_ADDITIONAL_INFORMATION_TAG },
    { label: 'Pollen', tag: POLLEN_ADDITIONAL_INFORMATION_TAG },
    { label: 'Weight', tag: WEIGHT_ADDITIONAL_INFORMATION_TAG },
    { label: 'Individual Tracking', tag: INDIVIDUAL_TRACKING_ADDITIONAL_INFORMATION_TAG },
];
