import { useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { Token } from 'aidbox-react/src/services/token';

import { restoreToken } from 'services/auth';
import { Epic } from 'containers/Epic';
import { SignIn } from 'containers/SignIn';

function AppNavigation() {
    const navigate = useNavigate();

    const [appToken, setAppToken] = useState<Token | undefined>(restoreToken());

    const renderAnonymousRoutes = () => (
        <Routes>
            <Route
                path='/signin'
                element={
                    <SignIn
                        onSignIn={(token) => {
                            setAppToken(token);
                            navigate('/epic/');
                        }}
                    />
                }
            />
            <Route path='*' element={<Navigate to={{ pathname: '/signin' }} replace />} />
        </Routes>
    );

    const renderAuthenticatedRoutes = () => {
        return (
            <Routes>
                <Route
                    path='/epic/*'
                    element={
                        <Epic
                            onLogout={() => {
                                setAppToken(undefined);
                                navigate('/signin');
                            }}
                        />
                    }
                />
                <Route path='*' element={<Navigate to={{ pathname: '/epic/' }} replace />} />
            </Routes>
        );
    };

    return appToken !== undefined ? renderAuthenticatedRoutes() : renderAnonymousRoutes();
}

export function App() {
    return (
        <BrowserRouter>
            <AppNavigation />
        </BrowserRouter>
    );
}
