import moment from 'moment';

import { MedicationStatus } from 'components/Medications/types';
import { MedicationStatement } from 'contrib/aidbox';
import { parseFHIRDateTime } from 'utils/date';
import { periodDays, PeriodDuration } from 'utils/chart';

export function extractMedicationsMeasurements(
    medicationStatements: MedicationStatement[],
    period: PeriodDuration,
    endDate: moment.Moment = moment(),
) {
    return Array.from(Array(periodDays(period)).keys())
        .reverse()
        .map((weekDayIndex): MedicationStatus[] | undefined => {
            const dayOfWeekDate = endDate.clone().subtract(weekDayIndex, 'days');

            const medicationStatementFacts = medicationStatements.filter(
                (ms) => isFact(ms) && hasEffectiveDate(ms, dayOfWeekDate.clone()),
            );

            if (medicationStatementFacts.length === 0) {
                return undefined;
            }

            return medicationStatementFacts
                .map((ms) => {
                    return {
                        status: ms.status,
                        name: ms.medication.CodeableConcept!.text!,
                    } as MedicationStatus;
                })
                .sort((a, b) => a.name.localeCompare(b.name));
        });
}

export function hasEffectiveDate(medicationStatement: MedicationStatement, date: moment.Moment) {
    const effectiveDate =
        medicationStatement.effective?.dateTime && parseFHIRDateTime(medicationStatement.effective?.dateTime);
    return Boolean(effectiveDate && effectiveDate.isSame(date, 'day'));
}

export function isSchedule(medicationStatement: MedicationStatement) {
    return !medicationStatement.partOf;
}

export function isFact(medicationStatement: MedicationStatement) {
    return !!medicationStatement.partOf;
}

export function isActiveOrCompleted(medicationStatement: MedicationStatement) {
    return ['active', 'completed'].includes(medicationStatement.status);
}

export function isAnyMedicationCompleted(medicationStatuses: MedicationStatus[] | undefined): boolean {
    if (medicationStatuses === undefined) {
        return false;
    }

    for (const medicationStatus of medicationStatuses) {
        if (medicationStatus.status === 'completed') {
            return true;
        }
    }

    return false;
}
