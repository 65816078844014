import { ChartTypeRegistry } from 'chart.js';
import { Moment } from 'moment';
import classNames from 'classnames';

import { BarChart } from 'components/BarChart';
import {
    AdditionalInformationChartData,
    AdditionalInformationTag,
    POLLEN_ADDITIONAL_INFORMATION_TAG,
    SLEEP_ADDITIONAL_INFORMATION_TAG,
} from 'containers/Epic/PatientDetails/utils';
import { LinearChart } from 'components/LinearChart';
import { PeriodDuration } from 'utils/chart';

import s from './ActiveTabChart.module.css';

export interface ActiveTabChartProps {
    period: PeriodDuration;
    activeTab: AdditionalInformationChartData[];
    activeTabTag: AdditionalInformationTag;
    startDate: Moment;
}

function ChartSelector(props: {
    chartType: keyof ChartTypeRegistry;
    components: AdditionalInformationChartData[];
    period: PeriodDuration;
    startDate: Moment;
}) {
    const { chartType, components, period, startDate } = props;

    switch (chartType) {
        case 'line':
            return <LinearChart startDate={startDate} components={components} />;
        case 'bar':
            return <BarChart period={period} startDate={startDate} components={components} />;
        default:
            return null;
    }
}

export function ActiveTabChart(props: ActiveTabChartProps) {
    const { period, activeTab, activeTabTag, startDate } = props;

    switch (activeTabTag) {
        case SLEEP_ADDITIONAL_INFORMATION_TAG:
            return (
                <div className={s.container}>
                    <div className={s.chart}>
                        <BarChart period={period} startDate={startDate} components={activeTab} stacked />
                    </div>
                </div>
            );
        case POLLEN_ADDITIONAL_INFORMATION_TAG:
            return (
                <div className={s.container}>
                    <div className={s.chart}>
                        <BarChart period={period} startDate={startDate} components={activeTab} />
                    </div>
                </div>
            );
    }

    return (
        <div className={s.container}>
            {activeTab.map((component, index) => (
                <div
                    key={`${component.chartType}-${index}`}
                    className={classNames(s.chart, { [s.withTopMargin]: index > 0 })}
                >
                    <ChartSelector
                        chartType={component.chartType}
                        components={[component]}
                        period={period}
                        startDate={startDate}
                    />
                </div>
            ))}
        </div>
    );
}
