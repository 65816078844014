import { useEffect } from 'react';

import { getConditionDisplay } from 'utils/condition';
import { formatPatientName } from 'utils/patient';
import { usePatientInFocus } from 'containers/Epic/Header/hooks';

import { PatientInfo } from './types';
import s from './PatientDetails.module.css';

export function Overview(props: { patient: PatientInfo }) {
    const { patient } = props;

    const [, updatePatientInFocus] = usePatientInFocus();

    useEffect(() => {
        updatePatientInFocus(patient);

        return () => updatePatientInFocus(undefined);
    }, [patient, updatePatientInFocus]);

    return (
        <div className={s.patientInfo}>
            <div className={s.patientName}>{formatPatientName(patient)}</div>

            <div className={s.patientInfoBlock}>
                <div className={s.patientInfoItem}>
                    <div className={s.patientInfoTitle}>Patient Overview</div>
                </div>

                <div className={s.patientInfoItem}>
                    <div className={s.infoItemLabel}>Condition</div>
                    <div className={s.infoItemValue}>{getConditionDisplay(patient.condition)}</div>
                </div>

                {patient.epic ? (
                    <div className={s.patientInfoItem}>
                        <div className={s.infoItemLabel}>Birth Date</div>
                        <div className={s.infoItemValue}>{patient.epic.birthdate}</div>
                    </div>
                ) : null}
            </div>

            {patient.epic && (patient.epic.phone || patient.epic.email || patient.epic.organization) ? (
                <div className={s.patientInfoBlock}>
                    <div className={s.patientInfoItem}>
                        <div className={s.patientInfoTitle}>Contact Details</div>
                    </div>

                    {patient.epic.phone ? (
                        <div className={s.patientInfoItem}>
                            <div className={s.infoItemLabel}>Phone</div>
                            <div className={s.infoItemValue}>{patient.epic.phone}</div>
                        </div>
                    ) : null}

                    {patient.epic.email ? (
                        <div className={s.patientInfoItem}>
                            <div className={s.infoItemLabel}>Email</div>
                            <div className={s.infoItemValue}>{patient.epic.email}</div>
                        </div>
                    ) : null}

                    {patient.epic.organization ? (
                        <div className={s.patientInfoItem}>
                            <div className={s.infoItemLabel}>Organization</div>
                            <div className={s.infoItemValue}>{patient.epic.organization}</div>
                        </div>
                    ) : null}
                </div>
            ) : null}
        </div>
    );
}
