export type PeriodDuration = 'two-weeks' | 'month' | 'three-months';

export function periodDays(period: PeriodDuration) {
    switch (period) {
        case 'two-weeks':
            return 14;
        case 'month':
            return 29;
        case 'three-months':
            return 29 * 3;
    }
}

export interface ChartRange {
    min?: number;
    max?: number;
}

export interface ChartReferenceNorms {
    good?: number;
    normal?: number;
    bad?: number;
}

export function sameRange(a?: ChartRange, b?: ChartRange) {
    return a?.min === b?.min && a?.max === b?.max;
}

export function rangeUpperBound(measurements: Array<number | undefined>, range?: ChartRange) {
    return (
        range?.max ??
        measurements.reduce<number>((old, next) => (next !== undefined ? Math.max(old, next) : old), -Infinity)
    );
}

export function formatTimeScale(timeInMinutes: number) {
    const hours = Math.floor(timeInMinutes / 60);
    const minutes = Math.round(timeInMinutes % 60);

    if (hours && minutes) {
        return `${hours} hr ${minutes} min`;
    } else if (hours) {
        return `${hours} hours`;
    } else {
        return `${minutes} min`;
    }
}
