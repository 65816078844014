import { useService } from 'aidbox-react/lib/hooks/service';
import { service } from 'aidbox-react/lib/services/service';

import { dateTime, Observation, Patient } from 'contrib/aidbox';

export interface PatientListData {
    patient: Patient;
    medication_compliance_percantage?: number;
    juli_score?: Observation;
    next_contact_date?: dateTime;
    next_appointment_date?: dateTime;
}

export function usePatientList() {
    const [patientsListResponse] = useService(async () => {
        return service<Array<PatientListData>>({
            url: '/epic/sandbox/$patient-list',
            method: 'GET',
        });
    });

    return { patientsListResponse };
}
