import { PeriodDuration } from 'utils/chart';

export function periodBarPercentage(period: PeriodDuration) {
    switch (period) {
        case 'two-weeks':
            return 0.3;
        case 'month':
            return 0.5;
        default:
            return undefined;
    }
}
